
<style>
.body {
  background-color: #F4F5F9;
}

.selected-subitem .ss {
  color: #32A7FD
}

.selected-subitem {
  background-color: white;
}

.selected-item {
  background: linear-gradient(180deg, #2F9AFE 0%, #257DFE 45.31%, #309EFE 95.83%);
}

.selected-item .v-list-item__title,
.selected-item .v-list-item__icon {
  color: white;
}


.selected-item .sideicon {
  display: block;
}

.itms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: white;
}

.sideicon {
  width: 6px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 0px 20px 20px 0px;
  background: #32A7FD;
  position: relative;
  left: -36px;
  display: none;
}

.sbar {
  display: flex;
  flex: 2;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: var(--neutral-light-09, #FFF);
  box-shadow: 0px 12px 18px 0px rgba(0, 0, 0, 0.04);
  border-color: white;
  display: flex;
  margin-left: 10px;
}

.headerr {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 10vh;
  margin-top: 10px;
}

.sbarmdiv {
  display: flex;
  width: 40%;
  margin-left: 30px;
}

#not {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  margin: auto;
  text-align: center;
  color: white;
}

#not:hover {
  cursor: pointer;
}

@keyframes fadeio {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width:800px) {
  .headerr {
    width: 100%;
    margin: auto;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  .sbar {
    margin-left: 0px;
    width: 80%;
  }

  .sbarmdiv {
    display: block;
    width: 100%;
    margin-left: 30px;
  }
}
</style>
<template>
  <v-app id="inspire">
    <v-navigation-drawer style="width: fit-content;" v-model="drawer" app>
      <div class="itms">
        <v-list dense nav>

          <div v-for="item in items">
            <v-list-item class="mt-5 ml-5" :key="item.title" :to="item.to" link :active-class="'selected-item'"
              @click="changeClass(item)">
              <div>
                <div style="display: flex;">
                  <div class="sideicon"></div>

                  <v-list-item-icon class="mr-2">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content class="mr-5">
                    <v-list-item-title :id=item.title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-icon class="mr-2">
                    <v-icon>{{ item.arrow }}</v-icon>
                  </v-list-item-icon>
                </div>
              </div>
            </v-list-item>

            <v-list v-if="item.arrow == 'mdi-chevron-up'" class="ml-10" dense nav>
              <v-list-item :active-class="'selected-subitem'" v-for="subl in item.sublist" :to="subl.to">
                <v-list-item-content>
                  <v-list-item-title class="ss" :id=SubtleCrypto>{{ subl.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

          </div>
        </v-list>
      </div>
    </v-navigation-drawer>

    <v-main class="body">
      <v-row>
        <div class="headerr" app>

          <div class="sbarmdiv">
            <v-toolbar-title @click.stop="drawer = !drawer"
              style="color: #2F9AFE; font-weight: bold; margin-top: 10px; flex: 1;">{{
                titlehead }}</v-toolbar-title>

            <div label="Search" prepend-inner-icon="mdi-magnify" class="sbar">
              <v-icon aria-hidden="false">
                mdi-magnify
              </v-icon>
              <input placeholder="Search anything here" v-model="searchQuery">
              <button v-if="searchQuery != ''" @click="clearsearch()">x</button>
              <button v-if="searchQuery != ''" @click="search()">Search</button>
            </div>
            <div class="ml-5" id="not" @click="gotoorder()"></div>
          </div>


          <v-spacer></v-spacer>
          <v-switch class="mr-3" @click="changelang()" v-model="lang" true-value="2" false-value="1"
            :label="langindex[lang]"></v-switch>
        </div>
      </v-row>


      <router-view :language="lang"></router-view>
    </v-main>
  </v-app>
</template>

<script>

import * as a from '@/utils/auth-header';
export default {
  data() {
    return {
      lang: "1",
      langindex: {
        1: "English",
        2: "Arabic"
      },
      drawer: false,
      items: [],
      right: null,
      titlehead: "Dashboard",
      counters: 0,
      searchQuery: ""
    }
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
  methods: {
    endsWithNumber(str) {
      // Use a regular expression to check if the string ends with one or more digits
      return /\d$/.test(str);
    },
    search() {
      let path = this.$route.path
      const currentPath = this.$router.currentRoute.path;

      if ((path.startsWith("/itemslist/") || path.includes("itemslistsearchshop")) && this.endsWithNumber(path)) {
        const newPath = "/itemslistsearchshop/" + (
          this.searchQuery + "," +
          (this.$route.params.id.includes(",") ? this.$route.params.id.split(",")[1] : this.$route.params.id)
        );
        if (currentPath !== newPath) {
          this.$router.push({ path: newPath });
        }
      } else {
        const searchPath = "/itemslistsearch/" + this.searchQuery;
        if (currentPath !== searchPath) {
          this.$router.push({ path: searchPath });
        }
      }
    },
    clearsearch() {
      if (this.$route.path.includes("itemslistsearchshop"))
        this.$router.push({ path: '/itemslist/' + this.$route.params.id.split(",")[1] })

      else if (this.$route.path.includes("itemslistsearch"))
        this.$router.push({ path: '/itemslist/' })

      this.searchQuery=""
    },
    changelang() {
      localStorage.setItem('language', this.lang);
      window.location.reload()
    },
    loadApp() {
      if (JSON.parse(localStorage.getItem('language'))) {
        this.lang = localStorage.getItem('language');
      }
      else {
        this.lang = "1"
      }
      if (JSON.parse(localStorage.getItem('token'))) {
        this.items = [
          { title: 'Dashboard', icon: 'mdi-home', to: '/dashboard', arrow: 'mdi-chevron-down', sublist: [{ title: 'Overview', to: '/overview' }] },
          { title: 'Category', icon: 'mdi-shape-plus-outline', to: '/categories', arrow: "" },
          { title: 'Category data', icon: 'mdi-shape-plus-outline', to: '/categorymanagement', arrow: 'mdi-chevron-down', sublist: [{ title: 'Category list', to: '/categorymanagement' }, { title: 'New Category', to: '/categorymanagementnew' }] },
          { title: 'Shop', icon: 'mdi-store-outline', to: '/shoplist', arrow: 'mdi-chevron-down', sublist: [{ title: 'Shop list', to: '/shoplist' }, { title: 'New shop', to: '/shopnew' }] },
          { title: 'Items', icon: 'mdi-note-text-outline', to: '/itemslist', arrow: 'mdi-chevron-down', sublist: [{ title: 'Item list', to: '/itemslist' }, { title: 'New item', to: '/itemnew' }] },
          { title: 'Banners', icon: 'mdi-cellphone', to: '/Banners', arrow: 'mdi-chevron-down', sublist: [{ title: 'Banners', to: '/banners' }, { title: 'New banner', to: '/bannersnew' }] },
          { title: 'Orders', icon: 'mdi-package-variant-closed', to: '/orders', arrow: '' },
          { title: 'Notification', icon: 'mdi-bell-outline', to: '/notification', arrow: '' },
          { title: 'Logout', icon: 'mdi-logout', arrow: '' }
        ]
      }
      else {
        this.items = [
          { title: 'Dashboard', icon: 'mdi-home', to: '/dashboard', arrow: 'mdi-chevron-down', sublist: [{ title: 'Overview', to: '/overview' }] },
          { title: 'Category', icon: 'mdi-shape-plus-outline', to: '/categories', arrow: "" },
          { title: 'Shop', icon: 'mdi-store-outline', to: '/shoplist', arrow: 'mdi-chevron-down', sublist: [{ title: 'Shop list', to: '/shoplist' }, { title: 'New shop', to: '/shopnew' }] },
          { title: 'Items', icon: 'mdi-note-text-outline', to: '/itemslist', arrow: 'mdi-chevron-down', sublist: [{ title: 'Item list', to: '/itemslist' }, { title: 'New item', to: '/itemnew' }] },
          { title: 'Orders', icon: 'mdi-package-variant-closed', to: '/orders', arrow: '' }
        ]
      }

    },
    async changeClass(i) {
      if (i.title == "Logout" && this.$router.currentRoute.path != "/") {
        let auths = a.authHeader()
        let user = JSON.parse(localStorage.getItem('user_id'));
        let formData = {
          "fcm_token": "123",
          "user_id": user
        }
        try {
          const response = await this.$http.post('https://daylivery.site/Daylivery/users.php/log_out', formData, { headers: auths })
          if (response.status == 200) {
            localStorage.clear('token')
            localStorage.clear('user_id')
            localStorage.clear('ordercounts')
            window.location.reload()
            this.$router.push({ path: "/" })
          }
        } catch (error) {
          console.log(error);
        }
      }
      else {
        this.titlehead = i.title
        if (i.arrow != "") {
          if (i.arrow == 'mdi-chevron-up')
            i.arrow = 'mdi-chevron-down'

          else
            i.arrow = 'mdi-chevron-up'
        }
      }
    },
    gotoorder() {
      document.getElementById("not").style.backgroundColor = "red"
      document.getElementById("not").style.animation = ""
      window.location.href = '/#/orders'
    },
    async getCount() {
      try {
        let auths = a.authHeader()
        let t = Date.now()
        const response = await this.$http.get('https://daylivery.site/Daylivery/orders.php/bills/count&t=' + t, { headers: auths })
        if (response.status == 200) {
          this.counters = response.data.detail
        }
        else {
          localStorage.clear('token')
          localStorage.clear('user_id')
          localStorage.clear('ordercounts')
          window.location.reload()
          this.$router.push({ path: "/" })
        }
      } catch (error) {
        localStorage.clear('token')
        localStorage.clear('user_id')
        localStorage.clear('ordercounts')
        window.location.reload()
        this.$router.push({ path: "/" })
        console.log(error);
      }
    }
  },
  created() {
    if (window.innerWidth > 1200)
      this.drawer = true
    else
      this.drawer = false
    this.loadApp();

    if (JSON.parse(localStorage.getItem('token'))) {
      setInterval(async () => {
        this.getCount()
        if (!JSON.parse(localStorage.getItem('ordercounts')))
          localStorage.setItem('ordercounts', JSON.stringify(this.counters));
        else {
          let notf = this.counters - JSON.parse(localStorage.getItem('ordercounts'))
          if (notf > 0) {
            document.getElementById("not").style.backgroundColor = "rgb(105, 255, 105)"
            document.getElementById("not").style.animation = "fadeio 2s infinite"
            localStorage.setItem('ordercounts', JSON.stringify(this.counters))
            Notification.requestPermission().then(function (permission) {
              if (permission === "granted") {
                var notification = new Notification("Orders", {
                  body: notf + " New order received!",
                });
                notification.onclick = function () {
                  window.location.href = "/#/orders";
                };
              }
            });
          }
        }
      }, 300000);
    }
  }
}
</script>